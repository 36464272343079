.hero {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem;
  position: sticky;
  top: 0;
  background: white;
  width: 100%;
  z-index: 10;
}

.hero > div {
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.hero > div:active {
  transform: scale(0.95);
}

.hero img {
  width: 50px;
  height: 50px;
  transform: rotateZ(20deg);
}

.hero ul {
  list-style: none;
  display: flex;
  gap: 3rem;
}

.hero li,
.hero a {
  color: #b3b6b9;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
}
.hero a {
  color: inherit;
  text-decoration: none;
}
.hero li:hover {
  color: black;
}
.hero li:active,
.hero a:active {
  transform: scale(0.95);
}

.hero > div:nth-child(2) {
  display: none;
  font-size: 2rem;
}
@media only screen and (max-width: 450px) {
  nav {
    visibility: hidden;
    opacity: 0;
    position: fixed;
    top: 102px;
    left: 0;
    width: 100%;
    height: 100%;
    background: #3b3d42;
    transition: all 0.3s ease-in-out;
  }
  .hero ul {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem;
    margin-top: 5rem;
    margin-bottom: 5rem;
  }
  .hero li,
  .hero a {
    font-size: 2rem;
  }

  .show {
    opacity: 1;
    visibility: visible;
  }

  .hero > div:nth-child(2) {
    display: block;
  }
}
