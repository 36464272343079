@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Fira+Sans:wght@300;400;500;700&display=swap');

/* default styling */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
body {
  font-family: 'Fira Sans', sans-serif;
  font-weight: 300;
  background: #f7f7f8;
  color: #3b3d42;
}

section {
  padding-top: 8rem;
  padding-bottom: 8rem;
}

button {
  display: block;
  background: none;
  outline: none;
  color: inherit;
  border: none;
  font-weight: 700;
  font-size: 1rem;
  padding: 0;
  border-bottom: 1.5px solid #b3b6b9;
  cursor: pointer;
  text-transform: uppercase;
}

button:hover {
  color: #b3b6b9;
  border-color: #3b3d42;
}

h1 {
  font-size: 5rem;
  margin-bottom: 1.5rem;
}

h2 {
  color: #b3b6b9;
  font-size: 2rem;
  margin-bottom: 1rem;
}

h1,
h2 {
  font-weight: normal;
  font-family: 'Libre Caslon Text', serif;
}

p {
  font-size: 1.4rem;
  line-height: 1.5;
}

@media only screen and (max-width: 450px) {
  section {
    padding: 6rem 1rem 3rem 1rem;
  }
}
