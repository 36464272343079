.getInTouch {
  padding-left: 1rem;
  padding-right: 1rem;
}

.getInTouch p {
  margin-left: 20rem;
}
.getInTouch a {
  display: inline-block;
  margin-left: 20rem;
  margin-top: 1.5rem;
  color: #3b3d42;
  font-size: 1.5rem;
  font-weight: 500;
  text-decoration: none;
  padding-bottom: 0.5rem;
  border-bottom: 1.5px solid #b3b6b9;
}

.getInTouch a:hover {
  color: #b3b6b9;
  border-color: #3b3d42;
}

.getInTouch p:last-child {
  margin-top: 1rem;
}

@media only screen and (max-width: 450px) {
  .getInTouch p,
  .getInTouch a {
    margin-left: 0;
  }
}
