.intro {
  padding-left: 1rem;
  padding-right: 1rem;
}

.intro p {
  margin-left: 20rem;
}

.intro p {
  line-height: 1.5;
  margin-bottom: 1rem;
}
.hide {
  opacity: 0;
  transform: translateY(-10px);
}

.intro button {
  margin-top: 2rem;
  transition: all 0.5s ease-in-out;
}

.btnDown {
  transform: translateY(-7.5rem);
}
.show {
  transform: translateY(0);
  opacity: 1;
  transition: all 0.7s ease-in-out;
}

@media only screen and (max-width: 450px) {
  .intro p {
    margin-left: 0;
  }
}
